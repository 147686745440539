import axios, {AxiosError} from 'axios'
import { toRaw } from 'vue'
import { createToast, withProps, clearToasts } from 'mosha-vue-toastify'
import { vsToast } from '@isagaco/vuesaga'
import axiosRetry, {isIdempotentRequestError} from 'axios-retry'
import bus from '@/assets/bus'
import { getAccount } from '@/services/queries'
import {firebaseTools} from '@/services/firebase'
import { client as hasuraClient } from '@/services/hasura'
type roles = 'no-roles'|'user'|'staff'|'instanceAdmin'|'support' 

const tzOffset = (new Date()).getTimezoneOffset() * 60000 //offset in milliseconds

const getDirection = (heading: number) => {
  heading -= 22.5
  if (heading < 0) heading += 360
  const directions = ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw']
  let index = Math.round(heading/45)
  if (index === 8) index = 0
  const remainder = heading - 45 * index
  const direction = directions[index]
  return { direction, remainder: heading+ 180 }
}

const sizes = {
  n: { width: 42*.8, height: 76*.8 },
  ne: { width: 64*.8, height: 71*.8 },
  e: { width: 71*.8, height: 41*.8 },
  se: { width: 66*.8, height: 78*.8 },
  s: { width: 39*.8, height: 75*.8 },
  sw: { width: 62*.8, height: 75*.8 },
  w: { width: 71*.8, height: 42*.8 },
  nw: { width: 64*.8, height: 73*.8 },
}

const isNetworkError = (error: AxiosError) => {
  return error && // just to make sure
  !error.response && // if there is a response, it reached the server and not a network error
  error.code !== 'ECONNABORTED' // check that it isn't a timeout
}

const retryCondition = (error: AxiosError) => {
  return isNetworkError(error) // my custom check
  // || isIdempotentRequestError(error) // retry lib check
}
  axiosRetry(axios, {
  retries: 30,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: retryCondition,
  onRetry: (retryCount, error) => {
    console.info('retry', retryCount)
  }
})

export default {
  getDeviceID: async ({userID, deviceInfo, deviceID}) => {
    const response = await axios.post('getDeviceID', {
      userID,
      deviceID,
      model: deviceInfo.model,
      manufacturer: deviceInfo.manufacturer,
      platform: deviceInfo.platform,
      osVersion: deviceInfo.version,
      pluginsVersion: window.apkVersion || null
    })
    return response.data.deviceID
  },
  updateUser: async (authStore) => {
    const currentUser = await firebaseTools.getCurrentUser()
    const customClaims = await firebaseTools.getCustomClaims(currentUser)
    const role = customClaims['x-hasura-default-role'] as roles
    const result = await hasuraClient.query(getAccount(role), { firebaseID: currentUser?.uid }).toPromise()
    if (!result.data?.users) return
    await authStore.setAccount(result.data.users[0], customClaims)
    authStore.setParcelData(result.data?.users[0].parcels || [])
    authStore.setFavoriteRoutes(result.data?.users[0]?.favorite_routes || [])
  },
  getVehicleData: async (payload) => {
    const { documentType, documentID, name:plate, id } = payload
    createToast(withProps(vsToast, { type: 'loading', text: 'Acción en progreso' }), { hideProgressBar: true, timeout: 100000})
    try {
      await axios.post('vehicleData', { documentType, documentID, plate, id }, {timeout: 1000000})
      clearToasts()
      createToast(withProps(vsToast, { type: 'success', text: 'Acción realizada' }), { hideProgressBar: true, timeout: 3000})
    } catch (error) {
      let errorMessage = error.response.status === 404 ? 'Los documentos ingresados no concuerdan.' : 'Error en la consulta'
      clearToasts()
      createToast(withProps(vsToast, { type: 'error', text: errorMessage}), { hideProgressBar: true, timeout: 3000 })
    }
  },
  mutation: async (mutations, jsonb?, variables?, noToast?) => {
    try {
      if (!noToast) {
        createToast(withProps(vsToast, { type: 'loading', text: 'Acción en progreso' }), { hideProgressBar: true, timeout: 100000})
      }
      const url = 'mutation'
      mutations = toRaw(mutations)
      // console.info(mutations, jsonb, variables)
      const response = await axios.post(url, { mutations, jsonb, variables }, {timeout: 1000000})
      if (response.data.ok === false) {
        let errorMessage
        if (response.data.data.message.graphQLErrors) {
          if(response.data.data.message.graphQLErrors[0].message.includes('Uniqueness violation')) {
            errorMessage = 'Ya existe un registro con la información ingresada'
          }
        }
        throw new Error(errorMessage || 'Error editando la información')
      }
      if (!noToast) {
        clearToasts()
        createToast(withProps(vsToast, { type: 'success', text: 'Acción realizada' }), { hideProgressBar: true, timeout: 3000})
      }
      return response.data.data
    } catch (err) {
      if (!noToast) {
        clearToasts()
        createToast(withProps(vsToast, { type: 'error', text: err.message || 'No se pudo completar la acción' }), { hideProgressBar: true, timeout: 3000 })
      }
      console.error(err)
      throw(err)
    }
  },
  getDate:(dateToParse, offset) => {
    const tzOffset = (new Date()).getTimezoneOffset() * 60000 //offset in milliseconds
    return new Date(dateToParse.getTime() + (offset || 0) - (tzOffset)).toISOString().split('T')[0]
  },
  formatTimeAndDate: (time: Date, includeDate) => {
    if (!time) return '-'
    if (time.charAt && Number(time.charAt(time.length - 1))) {
      time += 'Z'
    }
    const date = new Date(time)
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const yesterday = new Date()
    yesterday.setHours(0, 0, 0, 0)
    yesterday.setDate(today.getDate() - 1)
    const weekAgo = new Date()
    weekAgo.setHours(0, 0, 0, 0)
    weekAgo.setDate(today.getDate() - 6)

    const locale = navigator.language
    const dtf = new Intl.DateTimeFormat(locale, {
      timeStyle: 'short',
      // hour12: true
    })
    if (date > today) {
      return dtf.format(date)
    } else if (date > yesterday) {
      const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' })
      return rtf.format(-1, 'day') + ', ' + dtf.format(date)
    } else if (date > weekAgo) {
      const rtf = new Intl.DateTimeFormat(locale, { weekday: 'long' })
      return rtf.format(date) + ', ' + dtf.format(date)
    }
    const rtf = new Intl.DateTimeFormat(locale)
    return rtf.format(date) + ', ' + dtf.format(date)
  },
  formatTime: (time: Date, timeStyle?: String) => {
    if (!time) return '-'
    if (time.charAt && Number(time.charAt(time.length - 1))) {
      time += 'Z'
    }
    const date = new Date(time)
    const locale = navigator.language
    const dtf = new Intl.DateTimeFormat(locale, {
      timeStyle: timeStyle || 'short',
      // hour12: true
    })
    return dtf.format(date)
  },
  formatDate: (time: Date) => {
    if (!time) return '-'
    if (time.charAt && Number(time.charAt(time.length - 1))) {
      time += 'Z'
    }
    const date = new Date(time)
    const locale = navigator.language
    const rtf = new Intl.DateTimeFormat(locale)
    return rtf.format(date)
  },
  convertMs: (ms: Number) => {
    var d, h, m, s
    s = Math.floor(ms / 1000)
    m = Math.floor(s / 60)
    s = s % 60
    h = Math.floor(m / 60)
    m = m % 60
    d = Math.floor(h / 24)
    h = h % 24
    h += d * 24
    return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s)
  },
  rgbToHex: (r, g, b) => '#' + [r, g, b]
  .map(x => x.toString(16).padStart(2, '0')).join(''),
  getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime())
  
    const dates = []
  
    while (date <= endDate) {
      dates.push(new Date(date).toISOString().split('T')[0])
      date.setDate(date.getDate() + 1)
    }
  
    return dates;
  },
  mapToProp: (data, prop) => {
    return data
      .reduce((res, item) => Object
        .assign(res, {
          [item[prop]]: 1 + (res[item[prop]] || 0)
        }), Object.create(null))
  },
  arrayToIDMap: (array) => {
    const idIndexMap = {}
    array.forEach((item, index) => {
      idIndexMap[item.id] = index
    })
    return idIndexMap
  },
  getCarHTML: (heading: number) => {
    const { direction, remainder } = getDirection(heading)
    // if (!bus[direction]) console.info(bus, heading, direction)
    const size = sizes[direction]
    return {
      html: bus[direction],
      remainder,
      size
    }
  },
  colors: [
    '#51DFB8',
    '#FF7570',
    '#282A43',
    '#3BBFFF',
    '#4654D4',
    '#E55934',
    '#8B80F9',
    '#74C3D4',
    '#FFC15E'
  ],
  brandColors: [
    '#2c3fe8',
    '#05c5d5',
    '#cd80d9',
    '#fe8511',
    '#59629e',
    '#f8463b',
    '#1eab55',
    '#282a43'
  ],
  getColor: (text) => {
    const colors = [
      '#F44336',
      '#FFEBEE',
      '#FFCDD2',
      '#EF9A9A',
      '#E57373',
      '#EF5350',
      '#F44336',
      '#E53935',
      '#D32F2F',
      '#C62828',
      '#B71C1C',
      '#FF8A80',
      '#FF5252',
      '#FF1744',
      '#D50000',
      '#E91E63',
      '#F48FB1',
      '#F06292',
      '#EC407A',
      '#E91E63',
      '#D81B60',
      '#C2185B',
      '#AD1457',
      '#880E4F',
      '#FF80AB',
      '#FF4081',
      '#F50057',
      '#C51162',
      '#9C27B0',
      '#CE93D8',
      '#BA68C8',
      '#AB47BC',
      '#9C27B0',
      '#8E24AA',
      '#7B1FA2',
      '#6A1B9A',
      '#4A148C',
      '#EA80FC',
      '#E040FB',
      '#D500F9',
      '#AA00FF',
      '#673AB7',
      '#B39DDB',
      '#9575CD',
      '#7E57C2',
      '#673AB7',
      '#5E35B1',
      '#512DA8',
      '#4527A0',
      '#311B92',
      '#B388FF',
      '#7C4DFF',
      '#651FFF',
      '#6200EA',
      '#3F51B5',
      '#9FA8DA',
      '#7986CB',
      '#5C6BC0',
      '#3F51B5',
      '#3949AB',
      '#303F9F',
      '#283593',
      '#1A237E',
      '#8C9EFF',
      '#536DFE',
      '#3D5AFE',
      '#304FFE',
      '#2196F3',
      '#90CAF9',
      '#64B5F6',
      '#42A5F5',
      '#2196F3',
      '#1E88E5',
      '#1976D2',
      '#1565C0',
      '#0D47A1',
      '#82B1FF',
      '#448AFF',
      '#2979FF',
      '#2962FF',
      '#03A9F4',
      '#81D4FA',
      '#4FC3F7',
      '#29B6F6',
      '#03A9F4',
      '#039BE5',
      '#0288D1',
      '#0277BD',
      '#01579B',
      '#80D8FF',
      '#40C4FF',
      '#00B0FF',
      '#0091EA',
      '#00BCD4',
      '#80DEEA',
      '#4DD0E1',
      '#26C6DA',
      '#00BCD4',
      '#00ACC1',
      '#0097A7',
      '#00838F',
      '#006064',
      '#18FFFF',
      '#00E5FF',
      '#00B8D4',
      '#009688',
      '#80CBC4',
      '#4DB6AC',
      '#26A69A',
      '#009688',
      '#00897B',
      '#00796B',
      '#00695C',
      '#004D40',
      '#64FFDA',
      '#1DE9B6',
      '#00BFA5',
      '#4CAF50',
      '#A5D6A7',
      '#81C784',
      '#66BB6A',
      '#4CAF50',
      '#43A047',
      '#388E3C',
      '#2E7D32',
      '#1B5E20',
      '#69F0AE',
      '#00E676',
      '#00C853',
      '#8BC34A',
      '#AED581',
      '#9CCC65',
      '#8BC34A',
      '#7CB342',
      '#689F38',
      '#558B2F',
      '#33691E',
      '#B2FF59',
      '#76FF03',
      '#64DD17',
      '#CDDC39',
      '#DCE775',
      '#D4E157',
      '#CDDC39',
      '#C0CA33',
      '#AFB42B',
      '#9E9D24',
      '#827717',
      '#EEFF41',
      '#C6FF00',
      '#AEEA00',
      '#FFEB3B',
      '#FFEE58',
      '#FFEB3B',
      '#FDD835',
      '#FBC02D',
      '#F9A825',
      '#F57F17',
      '#FFEA00',
      '#FFD600',
      '#FFC107',
      '#FFD54F',
      '#FFCA28',
      '#FFC107',
      '#FFB300',
      '#FFA000',
      '#FF8F00',
      '#FF6F00',
      '#FFD740',
      '#FFC400',
      '#FFAB00',
      '#FF9800',
      '#FFB74D',
      '#FFA726',
      '#FF9800',
      '#FB8C00',
      '#F57C00',
      '#EF6C00',
      '#E65100',
      '#FFD180',
      '#FFAB40',
      '#FF9100',
      '#FF6D00',
      '#FF5722',
      '#FFAB91',
      '#FF8A65',
      '#FF7043',
      '#FF5722',
      '#F4511E',
      '#E64A19',
      '#D84315',
      '#BF360C',
      '#FF9E80',
      '#FF6E40',
      '#FF3D00',
      '#DD2C00',
      '#795548',
      '#A1887F',
      '#8D6E63',
      '#795548',
      '#6D4C41',
      '#5D4037',
      '#4E342E',
      '#3E2723',
      '#9E9E9E',
      '#BDBDBD',
      '#9E9E9E',
      '#757575',
      '#616161',
      '#424242',
      '#212121',
      '#607D8B',
      '#B0BEC5',
      '#90A4AE',
      '#78909C',
      '#607D8B',
      '#546E7A',
      '#455A64',
      '#37474F',
      '#263238',
    ]
    let ascii = 0;
    for (let index = 0; index < text.length; index++) {
      ascii += text.charCodeAt(index);
    }
    return colors[ascii % colors.length];
  }
}