import { useStorage } from '@vueuse/core'

export const state = useStorage('store', {
  jwt: '',
  jwtRefresh: '',
  customClaimsUpdate: null,
  deviceID: '',
  osVersion: '',
  pluginsVersion: '',
  searchKey: '',
})

export const mapSettings = useStorage('mapSettings', {
  trafficEnabled: false,
  isHybrid: false,
  showRoute: false,
})


