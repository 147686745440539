import Typesense, {Client} from 'typesense'
import { state } from '@/services/localStorage'
import axios from 'axios'

// Singleton pattern
let typesenseClient: Client | null = null
let actualKey = ''

export const client = () => {
  if (!typesenseClient || actualKey !== state.value.searchKey) {
    actualKey = state.value.searchKey
    typesenseClient = new Typesense.Client({
      'nodes': [{
        'host': 'search.rutasegura.co', // For Typesense Cloud use xxx.a1.typesense.net
        'port': 443,      // For Typesense Cloud use 443
        'protocol': 'https'   // For Typesense Cloud use https
      }],
      'apiKey': actualKey,
      'connectionTimeoutSeconds': 2
    })
  }
  return typesenseClient
}
let baseURL = ''
if (window.location.hostname === 'localhost') {
  baseURL = 'http://localhost:5001/buseticas-7f9fd/us-central1/'
} else {
  baseURL = import.meta.env.DEV
  ? `https://${import.meta.env.VITE_TAIL}/buseticas-7f9fd/us-central1/`
  : 'https://us-central1-buseticas-7f9fd.cloudfunctions.net/'
}
export const renovateClient = async (instanceID: string) => {
  // Change the key to force the client to be recreated
  const keyResponse = await axios.post(`${baseURL}generateTypesenseApiKey`, {
    instanceID
  })
  if (keyResponse.status === 200) {
    state.value.searchKey = keyResponse.data.apiKey
  }
  client()
}

export const runSearch = async (searchText, searches, instanceID) => {
  if (!searchText) {
    return
  }
  const searchRequests = {
    searches
  }
  const commonSearchParams =  {
    q: searchText,
    prioritize_exact_match: true,
  }
  let searchResult
  try {
    if (state.value.searchKey === '') throw new Error('Forbidden - a valid `x-typesense-api-key` header must be sent')
    searchResult = await client().multiSearch.perform(searchRequests, commonSearchParams)
  } catch (error) {
    if (((error as Error).message).includes('Forbidden')) {
      await renovateClient(instanceID)
      searchResult = await client().multiSearch.perform(searchRequests, {...commonSearchParams, q: searchText})
    }
  }
  return searchResult
}
